.App {
  text-align: center;
}

.div-modal {
  top: 100px;
  left: 0;
  background-color: rgb(250, 247, 250);
  padding: 10px;
  /* display: none; */
  border-radius: 20px;
  position: fixed;
  width: 80%;
  height: 200px;
  left: 10%;
  color: black;
  border-style: ridge;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.btn{
  margin: 2px;
  height: 5rem;
  width: 5rem;
}

.btn-codigo-active{
  margin: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  width: 100%;
  border-radius: 30px;
  border-style: none;
  background-color: #c2cee5;
}

.btn-codigo-save{
  margin: 2px;
  height: 40px;
  width: 100%;
  border-radius: 20px;
}

.btn-codigo-close{
  margin-top: 10px;
  /* margin: 2px; */
  height: 40px;
  width: 100%;
  border-radius: 20px;
}

.divbtn{
  float: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.div0 {
  padding-top: 10px;

  display: block;
  /* align-items: first baseline; */
}

/*.custom-btn {*/
/*  background: #fff;*/
/*  color: red;*/
/*  border: 2px solid red;*/
/*  border-radius: 3px;*/
/*  padding: 5px 10px;*/
/*  text-transform: uppercase;*/
/*}*/


.btn-evento {
  width: 20%;
  height: 5ch;
  /* display: flex !important; */
  justify-content:center !important;
  align-items:center !important;
  /* position:relative !important; */
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  /*lighting-color: #3dd5f3;*/
  --bs-btn-hover-bg: #61bfe8 !important;
  /* margin: auto; */
  /* width: 100%; */

  /* border: 2px solid red; */
  /* padding-top: 5px; */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  /* display: inline; */
  /* align-items: center !important; */
  /* align-self: center !important; */
  /* align-self: center; */
  /* image-orientation: flip !important; */
  /* align-items: baseline; */
  /*background-color: rgb(54, 188, 221,32) !important;*/

  /* text-align: center; */
  /* align-items: center; */
}


.btn-light {
  margin: 10px;
  display: inline-flex;
  overflow-wrap: break-word;
  color: red;
  size: 30ch !important;
}

.btn-icon {
  display: inline-flex;
  flex-flow: row nowrap;
  min-height: 50px !important;
  size: 200ch !important;
  align-items: center;
  justify-content: space-between;
}

#div-titulo {
  margin-top: 4px;
  margin-bottom: 4px;
  border: 4px;
  padding: 4px;
  text-align: center;
  border-radiu194s: 4px;
  /*background-color: #969ba5;*/
  color: #fcfffd;
}

#id-titulo {
  border: 0;
  padding: 10px;
  width: 100%;
  height: 20px;
  display: inline-table;
  margin: 0px;
  text-align: center;
  border-radius: 30px;
  background-color: #c2cee5 !important;
  color: #070707;
  font-Size: 18px;
}


#div-subtitulo {
  margin-top: 4px;
  margin-bottom: 4px;
  border: 4px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  /*background-color: #969ba5;*/
  /*color: #fcfffd;*/
  /*font-Size: 26px;*/
}

#id-subtitulo {
  border: 0;
  padding: 10px;
  width: 100%;
  height: 40px;
  display: inline-table;
  margin: 0px;
  text-align: center;
  border-radius: 30px;
  /*background-color: #555658 !important;*/
  background-color: #c2cee5 !important;
  color: #070707;
  font-Size: 22px;
}

#div-rodizio {
  margin-top: 4px;
  margin-bottom: 4px;
  border: 4px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  /*background-color: #969ba5;*/
  color: #fcfffd;
  overflow: auto;
  width: 100%;
  height: 550px;

  flexDirection: column !important;
  justifyContent: flex-start;

}


#id-campo1 {
  border: 0;
  /*padding: auto;*/
  width: 100%;
  height: 50px;
  /*display: inline-table;*/
  margin: auto;
  /*text-align: center;*/
  border-radius: 20px !important;
  color: #030303;
  background-color: #3dd5f3;
  font-Size: 26px;
  font-family: Verdana, Geneva, Tahoma, sans-serif, Helvetica, sans-serif;
  /* font-weight: bold; */
  /* font-Size: 28  'px; */
}

#div-campo2 {
  /* font-size: 100%; */
  overflow: auto;
}


#id-campo2 {
  /* padding: 2px; */
  width: 100%;
  margin: 3px;
  display: inline-table;
  background: #f7f9fa;
  color: #0404f3;
  /* font-size: 100%; */
  font-Size: 26px;
  font-family: Verdana, Geneva, Tahoma, sans-serif, Helvetica, sans-serif;
  /*font-weight: bold;*/
  /* padding: "2px", */
}

#div-campo3 {
  /* font-size: 100%; */
  /*overflow: auto;*/
}

#id-campo3 {
  padding: 2px;
  width: 100%;
  margin: 3px;
  display: inline-table;
  background: #f7f9fa;
  color: #d10707;
  font-Size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif, Helvetica, sans-serif;
  font-weight: bold;
}



#div-msg {
  width: 100%;
  height: 100%;
  /*background-color: #f50a5c;*/
  /* font-size: 100%; */
  /*overflow: auto;*/
}


#id-msg {
  /* padding: 2px; */
  width: 100%;
  margin: 3px;
  display: inline-table;
  background: #f7f9fa;
  background-color: #f50a5c;
  color: #f3f304;
  /* font-size: 100%; */
  font-Size: 28px;
  font-family: Verdana, Geneva, Tahoma, sans-serif, Helvetica, sans-serif;
  font-weight: bold;
}